body
{
  font-family: "Lato", sans-serif;
}

p
{
  color: #100202;
  font-weight: bold;
}

.App-section
{
  background-color: #EFF7FB;
  max-height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-section-title
{
  position: absolute;
  top: 6%;
  right: 10%;
  font-size: 1.5rem;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
}

.App-section-paragraph
{
  margin: 4%;
  font-size: 1rem;
  font-weight: normal;
}

#html5qr-code-full-region
{
  background-color:white;
  font-size: 0.8rem;
  font-weight: bold;
  width: 90%;
  min-height: 300px;  
}

img
{
  display: none;
}

.Result-container
{
  min-width: 90%;
  margin: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgb(69, 5, 16);
  color: #f1f7fd;
  font-weight: bold;
}

#html5-qrcode-anchor-scan-type-change
{
  height: 1.5rem;
  font-weight: 600;
  font-size: 0.7rem;
  border-radius: 6%;
}

.html5-qrcode-element
{  
  border: 2px solid black;
  border-radius: 15%;
  margin: 0.2rem;
  background-color: #ffffff;
  color: #4a0707;
  font-size: 0.7rem;
  border-radius: 5%;
  font-weight: bold;
  width: 12rem;
}

#html5qr-code-full-region__dashboard_section
{
  position: absolute;
  bottom: 7%;
  transform: translate(-3%, 15%);
}